import {
  BpkButtonV2,
  BUTTON_TYPES,
  SIZE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import ArrowIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';
import BpkImage, {
  withLazyLoading,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { getLocalFormattedDateFromISO } from '../localisation-helpers';

import type { ArticleCardProps } from '@skyscanner-internal/falcon-common-types/types/ArticleCardProps';

import STYLES from './ArticleCard.module.scss';

const isBrowser = typeof window !== 'undefined';
// Support for SSR
const documentIfExists = isBrowser ? document : null;
// withLazyLoading will not use document when rendered server side so it's safe
// pass null.
const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);

const AlignedArrowIcon = withButtonAlignment(withRtlSupport(ArrowIcon));

const ArticleCard = ({
  articleCreatedDate,
  articleHref,
  aspectRatio,
  author,
  authorHref,
  body,
  cta,
  fallbackArticleCreatedLocalised,
  image,
  title,
  variant,
}: ArticleCardProps) => {
  const articleCreatedLocalised = isBrowser
    ? getLocalFormattedDateFromISO({
        isoDate: articleCreatedDate,
      })
    : fallbackArticleCreatedLocalised;

  return (
    <div
      data-testid="article-card"
      className={[STYLES.ArticleCard, STYLES[`ArticleCard__${variant}`]].join(
        ' ',
      )}
    >
      <a href={articleHref} className={STYLES.ArticleLink} aria-label={title} />
      <div className={[STYLES.Image, STYLES[`Image__${variant}`]].join(' ')}>
        <LazyLoadedImage src={image.src} altText="" aspectRatio={aspectRatio} />
      </div>

      <div className={STYLES[`ArticleTitle__${variant}`]}>
        <BpkText tagName="h3">{title}</BpkText>
      </div>

      <div className={STYLES.DateAuthor}>
        <BpkText tagName="p" textStyle={TEXT_STYLES.footnote}>
          <time dateTime={articleCreatedDate}>{articleCreatedLocalised}</time>
        </BpkText>

        <BpkLink rel="author" href={authorHref} className={STYLES.AuthorLink}>
          {author}
        </BpkLink>
      </div>

      {variant === 'cardSet' && (
        <>
          {body && (
            <div className={STYLES.Body}>
              <BpkText tagName="p" textStyle={TEXT_STYLES.bodyLongform}>
                {body}
              </BpkText>
            </div>
          )}
          {cta && (
            <div className={STYLES.Cta}>
              <BpkButtonV2
                type={BUTTON_TYPES.link}
                size={SIZE_TYPES.large}
                href={articleHref}
              >
                {cta.label} <AlignedArrowIcon />
              </BpkButtonV2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArticleCard;
